
const BASE_API = 'https://yuriykalugin.ru/'

export async function auth(login: string, password: string): Promise<string> {
  try {
    const response = await fetch(BASE_API + 'api/auth', {
      body: JSON.stringify({ login, password }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const data = await response.json();
    if (data.jwt) {
      return data.jwt;
    }
  } catch (e) {
    console.log(e);
    throw new Error('Something went wrong');
  }
  throw new Error('Wrong login/password');
}
