import React, {useCallback, useContext, useState} from 'react';
import HomePage from './pages/home';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import InnerStartPage from './pages/innerStart';
import {MallocSimulatorPage} from './pages/mallocSimulator';
import TetrisPage from './pages/tetris';
import PayWithCardTest from './pages/payWithCardTest';
import TopBarMessage, {TopBarMessageType, TopMessageContext} from './components/topBarMessage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/memoryAllocationSimulator',
    element: <MallocSimulatorPage />,
  },
  {
    path: '/tetris',
    element: <TetrisPage />,
  },
  {
    path: '/payWithCardTest',
    element: <PayWithCardTest />,
  },
  {
    element: <AuthLayout />,
    children: [{
      path: '/test',
      element: <InnerStartPage />,
    }],
  }
]);

export default function App(props: unknown) {

  const [topBarText, setTopBarText] = useState('');
  const [topBarType, setTopBarType] = useState<TopBarMessageType>('ok');

  const updateTopBar = useCallback((type: TopBarMessageType, text: string) => {
    setTopBarText(text);
    setTopBarType(type);
  }, [topBarText,topBarType]);

  return (
    <TopMessageContext.Provider value={{
      type: topBarType,
      message: topBarText,
      setMessage: updateTopBar
    }}>
      <TopBarMessage />
      <RouterProvider router={router} />
    </TopMessageContext.Provider>
  );
}
