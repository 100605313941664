import React, {useEffect, useRef} from 'react';
import {TetrisEngine} from './TetrisEngine';

export default class TetrisPage extends React.Component<any, any> {

  ref = React.createRef<HTMLCanvasElement>();
  engine!: TetrisEngine

  componentDidMount() {
    const ctx = this.ref.current?.getContext('2d')!;
    this.engine = new TetrisEngine(ctx);
    this.engine.start();
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    this.engine.stop();
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      this.engine.move('left');
    } else if (event.key === 'ArrowRight') {
      this.engine.move('right');
    }
  }

  render() {
    return (
      <div style={{padding: 20}}>
        <canvas ref={this.ref} width="400" height="480"/>
      </div>
    );
  }
}
