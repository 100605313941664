
const SQUARE_SIZE = 20;
const COUNT_Y = 20;
const COUNT_X = 10;

export class TetrisEngine {
  private ctx: CanvasRenderingContext2D
  private tetris!: Tetris
  private fallen: Array<Array<string | null>>
  private interval: any

  constructor(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx;
    this.tetris = new IShape();
    this.fallen = Array.from(new Array(COUNT_Y), () => Array.from(new Array(COUNT_X), () => null));
  }

  start() {
    this.interval = setInterval(this.tick.bind(this), 500);
  }

  stop() {
    clearInterval(this.interval);
  }

  move(direction: 'right' | 'left') {
    const add = direction === 'right' ? 1 : -1;
    this.tetris.iterateShapes((x, y) => {
      const newX = x + add;
      if (newX < 0 || newX >= COUNT_X || this.fallen[y][newX] !== null) return;
    });
    this.tetris.move(direction);
  }

  private tick() {
    if (this.checkCollision()) {
      this.tetrisFall();
    } else {
      this.tetris.moveDown();
    }
    this.draw();
  }

  private tetrisFall() {
    this.tetris.iterateShapes((x, y) => {
      this.fallen[y][x] = this.tetris.color;
    });
    this.tetris = new IShape();
  }

  private checkCollision() {
    let result = false;

    this.tetris.iterateShapes((x, y) => {
      if (y === COUNT_Y - 1) result = true;
      else if (this.fallen[y + 1][x] !== null) result = true;
    })
    return result;
  }

  private draw() {
    this.ctx.clearRect(0, 0, SQUARE_SIZE * COUNT_X, SQUARE_SIZE * COUNT_Y);
    this.drawBackground();
    this.drawTetris();
    this.drawFallen();
  }

  private drawFallen() {
    for (let y = 0; y < COUNT_Y; y++) {
      for (let x = 0; x < COUNT_X; x++) {
        if (this.fallen[y][x] !== null) {
          this.ctx.fillStyle = 'black';
          this.ctx.fillRect(x * SQUARE_SIZE, y * SQUARE_SIZE, SQUARE_SIZE , SQUARE_SIZE);
          this.ctx.fillStyle = this.fallen[y][x]!;
          this.ctx.fillRect(x * SQUARE_SIZE, y * SQUARE_SIZE, SQUARE_SIZE -1 , SQUARE_SIZE - 1);
        }
      }
    }
  }

  private drawTetris() {
    this.tetris.iterateShapes((X, Y) => {
      this.ctx.fillRect(X * SQUARE_SIZE, Y * SQUARE_SIZE, SQUARE_SIZE, SQUARE_SIZE);
      this.ctx.fillStyle = this.tetris.color;
      this.ctx.fillRect(X * SQUARE_SIZE, Y * SQUARE_SIZE, SQUARE_SIZE -1, SQUARE_SIZE -1);
    });
  }

  private drawBackground() {
    this.ctx.fillStyle = 'white';
    this.ctx.fillRect(0, 0, (SQUARE_SIZE - 1) * (COUNT_X ), SQUARE_SIZE * (COUNT_Y));
    this.ctx.fillStyle = 'violet';
    for (let y = 0; y < COUNT_Y; y++) {
      for (let x = 0; x < COUNT_X; x++) {
        const X = x * SQUARE_SIZE;
        const Y = y * SQUARE_SIZE;
        this.ctx.fillRect(X + 1, Y + 1, SQUARE_SIZE -1, SQUARE_SIZE - 1);
      }
    }
  }
}

abstract class Tetris {
  color: string
  shape!: Array<Array<number>>
  x: number = 0
  y: number = 0

  protected constructor() {
    this.color = colors[Math.floor(Math.random() * colors.length)];
  }

  iterateShapes(callback: (( X: number, Y: number, shapeX: number, shapeY: number) => void)) {
    const shapes = this.shape;
    for (let y = 0; y < shapes.length; y++) {
      for (let x = 0; x < shapes[y].length; x++) {
        if (shapes[y][x] === 0) continue;
        callback(x + this.x, y + this.y, x, y);
      }
    }
  }

  moveDown() {
    this.y++;
  }

  move(direction: 'right' | 'left') {
    this.x += direction === 'right' ? 1 : -1;
  }

  rotate() {
    let newShape = new Array(this.shape.length)
    for (let x = 0; x < newShape.length; x++) {
      newShape[x] = new Array(this.shape[x].length);
      for (let y = 0; y < this.shape[x].length; y++) {
        newShape[x][y] = this.shape[y][x];
      }
    }
    this.shape = newShape;
  }
}


class IShape extends Tetris {
  constructor() {
    super();
    this.shape = [
      [ 0, 1, 0, 0],
      [ 0, 1, 0, 0],
      [ 0, 1, 0, 0],
      [ 0, 1, 0, 0]
    ];
  }
}

class SquareShape extends Tetris {
  constructor() {
    super();
    this.shape = [
      [1, 1],
      [1, 1]
    ];
  }
}

type Cord = {
  x: number;
  y: number;
}

const colors = ['red', 'blue', 'yellow', 'green'];

/*
𝑥2=cos𝛽𝑥1−sin𝛽𝑦1
𝑦2=sin𝛽𝑥1+cos𝛽𝑦1
 */
