import React, {useState} from 'react';
import {redirect, useNavigate} from 'react-router-dom';
import {auth} from '../../api/api';
import Button from '@mui/material/Button';
import {TextField} from '@mui/material';
import styles from './home.module.scss';

export default function HomePage(props: unknown) {
    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const navigate = useNavigate();

    const onClick = async () => {
      try {
          await auth(login, pass);
        navigate('/test')
      } catch (e: any) { alert(e.message); }
    };

    return (
        <div>
          <form className={styles.formContainer}>
            <TextField
              className={styles.input}
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              label="Login"
            />

            <TextField
              className={styles.input}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              label="Password"
            />

            <Button variant="contained" onClick={onClick} className={styles.button}>
              Proceed to authed
            </Button>
          </form>
        </div>
    )
}
